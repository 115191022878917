<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
                <small class="ml-2">Period</small>
                <div class='flex align-items-center'>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.date_start" @input='getData()'></flat-pickr>
                    <small class='ml-5 mr-5'>Until</small>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.date_end"  @input='getData()'></flat-pickr>
                </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-contact class="w-full" :isvendor="true" name="vendor_id" label="Vendor" v-model="filterData.vendor_id" v-on:onChangeContact="changeVendor"></browse-contact>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Payables List">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="primary" @click="is_filter = !is_filter">Filter</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="id">Type</vs-th>
            <vs-th sort-key="bill_name">Billed To</vs-th>
            <vs-th sort-key="date_transaction">Date</vs-th>
            <vs-th sort-key="grand_total">Grand Total</vs-th>
            <vs-th sort-key="grand_total">Leftover</vs-th>
            <vs-th sort-key="created_at">Status</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.code">
                <vs-chip v-if="tr.job_order_cost_id" color="primary">Job Order Cost</vs-chip>
                <vs-chip v-if="tr.manifest_cost_id" color="success">Manifest Cost</vs-chip>
              </vs-td>
              <vs-td label="Billed To" :data="tr.bill_name">{{tr.bill_name}}</vs-td>
              <vs-td label="Date" :data="tr.date_transaction">{{tr.date_transaction|moment('Do MMM YYYY')}}</vs-td>
              <vs-td label="Grand Total" class="text-right" :data="tr.grand_total">{{tr.grand_total|numFormat}}</vs-td>
              <vs-td label="Leftover" class="text-right" :data="tr.leftover">{{tr.leftover|numFormat}}</vs-td>
              <vs-td>
                <vs-chip v-if="tr.leftover<=0" color="success">Paid Off</vs-chip>
                <vs-chip v-if="tr.leftover>0" color="danger">Unpaid</vs-chip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import BrowseContact from '@browse/Contact.vue'

export default {
  components:{
    flatPickr,
    BrowseContact
  },
  data(){
    return {
      filterData : {},
      is_filter : false,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      flatPickrConfig: {
            altFormat: 'j M Y',
            altInput: true,
            dateFormat: 'Y-m-d',
      }
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.payables.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'payables/index'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      payload = Object.assign(payload, this.filterData)
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async changeVendor(data){
      this.filterData.vendor_id = data.id
      await this.getData();
    },
    async resetFilter() {
      this.filterData = {}
      await this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>